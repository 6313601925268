import { jsx as a } from "react/jsx-runtime";
import { useState as T } from "react";
import "uuid";
import { useMatchMedia as f } from "../../../hooks/useMatchMedia.js";
import { Theme as e } from "../ThemeContext.js";
import { ThemeProvider as p } from "../ThemeProvider/ThemeProvider.js";
const y = "(prefers-color-scheme: dark)", v = (t) => {
  const { children: m, saveTheme: o, getTheme: s, initialTheme: h = e.auto } = t, i = f(y) ? e.dark : e.light, [n, c] = T(() => s() || h);
  return /* @__PURE__ */ a(p, { onThemeChange: (r) => {
    o(r), c(r);
  }, theme: n, systemTheme: i, children: m });
};
export {
  v as ThemeSyncWithSystemProvider
};
