import { jsx as e, jsxs as n } from "react/jsx-runtime";
import i from "@jetbrains/ring-ui-built/components/button-group/button-group.js";
import { Button as d } from "@jetbrains/ring-ui-built/components/button/button.js";
import { memo as f } from "react";
import { insertSpacesInStr as p } from "../../utils/string.js";
import u from "./Tabs.module.css.js";
const B = f((r) => {
  const { className: s, disabled: m = !1, onSelectTab: c, options: o, selected: l } = r, a = (t) => () => c(t);
  return /* @__PURE__ */ e(i, { className: s, children: o.map((t) => /* @__PURE__ */ n(
    d,
    {
      active: l === t.id,
      disabled: m,
      onClick: a(t.id),
      ...t.htmlProps,
      children: [
        t.title,
        !!t.count && /* @__PURE__ */ e("span", { className: u.count, children: p(String(t.count)) })
      ]
    },
    t.id
  )) });
});
export {
  B as Tabs
};
