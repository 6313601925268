export const QD_CLOUD_LOCAL_STORAGE_KEY = 'qodana-cloud-storage.v1.'

// use '.' only to distinct version
export const versionDelimiter = '.'

export enum StorageUnits {
	THEME = 'theme.v1.',
	IS_USER_ONBOARDED = 'is-user-onboarded.v1',
	CURRENT_ORGANIZATION_ID = 'current-organization-id.v1',
	IS_NEWSLETTER_VIEWED = 'is-newsletter-viewed.v1',
	PROMO_MODAL_VIEW_DATE = 'promo-modal-reset-date.v1',
	PRE_REDIRECT_URL_STATE = 'pre-redirect-url-state',
	IS_INSIGHTS_HINT_SEEN = 'is-insights-hint-seen.v1',
}
