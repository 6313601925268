import { jsxs as c, jsx as o } from "react/jsx-runtime";
import y from "@jetbrains/icons/close-12px.js";
import { Button as l } from "@jetbrains/ring-ui-built/components/button/button.js";
import { ControlsHeight as C } from "@jetbrains/ring-ui-built/components/global/controls-height.js";
import n from "classnames";
import { Fragment as N } from "react";
import "../Typography/Typography.js";
import t from "./Banner.module.css.js";
import x from "../Typography/Typography.module.css.js";
function w(m) {
  const { children: d, type: r, action: e, className: s, onClose: i, "data-qd-test": p } = m, u = () => {
    if (!e)
      return;
    if ("custom" in e)
      return /* @__PURE__ */ o(N, { children: /* @__PURE__ */ o("span", { children: e.custom }) }, e.key);
    const { children: h, key: a, ...f } = e;
    return /* @__PURE__ */ o(
      l,
      {
        "data-qd-test": a,
        primary: !0,
        height: C.S,
        className: n(s, t.actionButton),
        ...f,
        children: h
      },
      a
    );
  };
  return /* @__PURE__ */ c("div", { className: n(t.banner, t[r], s), "data-qd-test": p, children: [
    /* @__PURE__ */ o("div", { className: n(t.text, x.smallText), children: d }),
    /* @__PURE__ */ c("div", { className: n(t.btnContainer, { [t.withAction]: !!e }), children: [
      u(),
      i && /* @__PURE__ */ o(
        l,
        {
          "aria-label": "Close",
          className: t.closeButton,
          iconClassName: n(t.closeIcon, t[`${r}CloseButton`]),
          onClick: i,
          icon: y
        }
      )
    ] })
  ] });
}
export {
  w as Banner
};
