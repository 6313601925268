import { jsx as m } from "react/jsx-runtime";
import { useRef as d, useEffect as c } from "react";
import { ThemeColor as s } from "../../../types.js";
import { isDark as f, updateBodyClass as p, updateMetaThemeColor as u } from "../../../utils/theme.js";
import { Theme as t, ThemeContext as T } from "../ThemeContext.js";
import C from "./ThemeProvider.module.css.js";
const R = (i) => {
  const { children: a, systemTheme: h = t.light, onThemeChange: l, theme: e = t.auto } = i, o = e === t.auto ? h : e, r = f(o) ? s.dark : s.light, n = d(null);
  return c(() => {
    p(o), u(e, r, n);
  }, [o, r, e]), /* @__PURE__ */ m(T.Provider, { value: { theme: e, onThemeChange: l }, children: /* @__PURE__ */ m("div", { className: C.themeContainer, children: a }) });
};
export {
  R as ThemeProvider
};
