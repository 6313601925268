import { jsxs as t, jsx as r } from "react/jsx-runtime";
import f from "@jetbrains/icons/download.js";
import I from "@jetbrains/icons/warning.js";
import { Button as u } from "@jetbrains/ring-ui-built/components/button/button.js";
import D from "@jetbrains/ring-ui-built/components/icon/icon.js";
import { Directions as N } from "@jetbrains/ring-ui-built/components/popup/popup.consts.js";
import h from "@jetbrains/ring-ui-built/components/popup/popup.js";
import S from "classnames";
import "react";
import "uuid";
import { useOpenCloseState as C } from "../../hooks/useOpenCloseState.js";
import "@jetbrains/ring-ui-built/components/global/theme.js";
import "../Theme/ThemeContext.js";
import "../ApplicationMenu/ApplicationMenuContext.js";
import "../../utils/applicationMenu/applicationMenu.js";
import { DOWNLOAD_SUBMENU_ID as O } from "../ApplicationMenu/constants.js";
import { useSubscribeSubMenuItems as b } from "../ApplicationMenu/useSubscribeSubMenuItems.js";
import { SmallText as w } from "../Typography/Typography.js";
import e from "./DownloadSelectButton.module.css.js";
const _ = 10, G = () => {
  const [m, s, i] = C(!1), l = b(O), c = (o) => {
    i(), o && o();
  };
  return /* @__PURE__ */ t("div", { children: [
    /* @__PURE__ */ r(
      u,
      {
        icon: f,
        short: !0,
        inline: !1,
        onClick: s,
        title: "Download SARIF file",
        "aria-label": "Download SARIF file",
        iconClassName: "scalableIcon"
      }
    ),
    /* @__PURE__ */ r(
      h,
      {
        directions: [N.BOTTOM_LEFT],
        hidden: !m,
        onEscPress: i,
        onOutsideClick: i,
        children: l.map(({ id: o, click: p, label: a, description: n, htmlProps: d }) => /* @__PURE__ */ t(
          "button",
          {
            "data-qd-test": "download-select-item",
            className: e.popupItem,
            onClick: () => c(p),
            ...d,
            children: [
              a,
              n && /* @__PURE__ */ t("div", { className: e.errorDescriptionContainer, children: [
                /* @__PURE__ */ r(
                  D,
                  {
                    size: _,
                    className: S(e.errorDescriptionIcon, "scalableIcon"),
                    glyph: I
                  }
                ),
                /* @__PURE__ */ r(w, { className: e.errorDescription, children: n })
              ] })
            ]
          },
          o
        ))
      }
    )
  ] });
};
export {
  G as DownloadSelectButton
};
