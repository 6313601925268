import { jsxs as f, jsx as a } from "react/jsx-runtime";
import y from "classnames";
import p, { useMemo as g } from "react";
import s from "./Skeleton.module.css.js";
const l = 200, k = p.memo((r) => {
  const { circle: t = !1, className: c = "", height: o, innerRadius: i, outerRadius: e, width: d } = r, m = t ? {
    borderRadius: "50%",
    height: e ? e * 2 : void 0,
    width: e ? e * 2 : void 0
  } : { height: o, width: d }, u = t ? {
    height: e ? e * 2 : void 0,
    width: e ? e * 2 + l : void 0
  } : { height: o, width: d ? d + l : void 0 }, h = g(() => {
    if (t && i && e) {
      const n = Math.min(i, e);
      return {
        height: n * 2,
        left: e - n + "px",
        top: e - n + "px",
        width: n * 2
      };
    } else
      return { display: "none" };
  }, [t, i, e]);
  return /* @__PURE__ */ f(
    "div",
    {
      className: y(s.container, c),
      style: m,
      "data-qd-test": r["data-qd-test"],
      children: [
        /* @__PURE__ */ a("div", { className: s.background, style: u }),
        /* @__PURE__ */ a("div", { className: s.innerCircle, style: h })
      ]
    }
  );
});
export {
  k as Skeleton
};
