import { Theme, ThemeSyncWithSystemProvider } from '@qodana/blocks'
import { PropsWithChildren } from 'react'

import { StorageUnits } from './config/localStorage'
import { getFromLocalStorage, saveToLocalStorage } from './utils/localStorage'

type AppearanceProps = PropsWithChildren<{}>

export const Appearance = (props: AppearanceProps) => {
	const { children } = props

	const getTheme = () => {
		const storageValue = getFromLocalStorage<string>(StorageUnits.THEME)

		if (storageValue === null || !Object.values(Theme).includes(storageValue as Theme)) {
			return undefined
		}

		return storageValue as Theme
	}

	const saveTheme = (theme: Theme) => saveToLocalStorage(StorageUnits.THEME, theme)

	return (
		<ThemeSyncWithSystemProvider getTheme={getTheme} saveTheme={saveTheme}>
			{children}
		</ThemeSyncWithSystemProvider>
	)
}
