const t = "_banner_1jeba_1", o = "_text_1jeba_10", n = "_btnContainer_1jeba_22", e = "_closeIcon_1jeba_31", c = "_actionButton_1jeba_36", _ = "_closeButton_1jeba_40", a = "_criticalCloseButton_1jeba_40", s = "_moderateCloseButton_1jeba_48", i = "_infoCloseButton_1jeba_56", l = "_critical_1jeba_40", b = "_moderate_1jeba_48", r = "_info_1jeba_56", u = "_withAction_1jeba_81", B = {
  banner: t,
  text: o,
  btnContainer: n,
  closeIcon: e,
  actionButton: c,
  closeButton: _,
  criticalCloseButton: a,
  moderateCloseButton: s,
  infoCloseButton: i,
  critical: l,
  moderate: b,
  info: r,
  withAction: u
};
export {
  c as actionButton,
  t as banner,
  n as btnContainer,
  _ as closeButton,
  e as closeIcon,
  l as critical,
  a as criticalCloseButton,
  B as default,
  r as info,
  i as infoCloseButton,
  b as moderate,
  s as moderateCloseButton,
  o as text,
  u as withAction
};
