const e = "_heading1_8rcd2_1", t = "_heading1Fixed_8rcd2_6", _ = "_heading2_8rcd2_20", c = "_heading3_8rcd2_30", d = "_accentText_8rcd2_42", n = "_accentTextFixed_8rcd2_47", o = "_smallText_8rcd2_59", s = "_smallText_semibold_8rcd2_66", x = "_semibold_8rcd2_71", a = "_smallText_upper_8rcd2_75", l = "_subtitle_8rcd2_80", i = "_text_8rcd2_87", r = "_text_italic_8rcd2_94", T = "_monoText_8rcd2_98", m = "_monoText_bold_8rcd2_107", g = "_secondaryText_8rcd2_112", h = {
  heading1: e,
  heading1Fixed: t,
  heading2: _,
  heading3: c,
  accentText: d,
  accentTextFixed: n,
  smallText: o,
  smallText_semibold: s,
  semibold: x,
  smallText_upper: a,
  subtitle: l,
  text: i,
  text_italic: r,
  monoText: T,
  monoText_bold: m,
  secondaryText: g
};
export {
  d as accentText,
  n as accentTextFixed,
  h as default,
  e as heading1,
  t as heading1Fixed,
  _ as heading2,
  c as heading3,
  T as monoText,
  m as monoText_bold,
  g as secondaryText,
  x as semibold,
  o as smallText,
  s as smallText_semibold,
  a as smallText_upper,
  l as subtitle,
  i as text,
  r as text_italic
};
