import { jsx as a } from "react/jsx-runtime";
import N from "@jetbrains/icons/copy.js";
import { Button as O } from "@jetbrains/ring-ui-built/components/button/button.js";
import P from "@jetbrains/ring-ui-built/components/tooltip/tooltip.js";
import p from "classnames";
import { useState as x, useRef as R, useEffect as _ } from "react";
import { showError as c } from "../../services/alert/alert.js";
import "../../services/focusRestore/FocusRestoreContext.js";
import "../../services/telemetry/telemetry.js";
import { copyToClipboard as b } from "../../utils/copyToClipboard.js";
import { TestAnchors as k } from "./test.js";
import D from "./CopyButton.module.css.js";
const B = 2e3, l = "Failed to copy", L = (m) => {
  const {
    textToCopy: u,
    children: f,
    iconClassName: y,
    className: C,
    showTooltipOnlyOnCopy: d,
    containerClassName: T,
    onCopy: t,
    ...w
  } = m, [e, r] = x(!1), n = R(null);
  _(() => () => {
    const o = n.current;
    o !== null && clearTimeout(o);
  }, []);
  const h = async (o) => {
    if (o) {
      t && t();
      try {
        return await b(o), !0;
      } catch {
        return c(l), !1;
      }
    } else
      return c(l), !1;
  }, s = async (o) => {
    o.stopPropagation(), await h(u) && (r(!0), n.current = setTimeout(() => r(!1), B));
  }, E = async (o) => {
    await s(o);
  }, I = async (o) => {
    (o.key === "Enter" || o.key === " ") && (o.preventDefault(), await s(o));
  }, i = d ? e : !0;
  return /* @__PURE__ */ a(i ? P : "span", { className: T, ...i ? { title: e ? "Copied" : "Copy" } : void 0, children: /* @__PURE__ */ a(
    O,
    {
      "data-qd-test": k.copyButton,
      onClick: E,
      onKeyDown: I,
      inline: !0,
      className: p(D.button, C),
      icon: N,
      iconClassName: p(y, "scalableIcon"),
      ...w,
      children: f
    }
  ) });
};
export {
  L as CopyButton
};
