import { jsxs as t, jsx as a } from "react/jsx-runtime";
import { useState as g, Fragment as x, createElement as k } from "react";
import q from "@jetbrains/icons/chevron-20px-down.js";
import j from "@jetbrains/icons/close.js";
import { Button as n } from "@jetbrains/ring-ui-built/components/button/button.js";
import s from "classnames";
import "../Typography/Typography.js";
import e from "./Notification.module.css.js";
import w from "../Typography/Typography.module.css.js";
const G = (i) => {
  const {
    className: f,
    children: r,
    type: N,
    title: c,
    onClose: d,
    actions: m,
    collapsible: p = !1,
    onCollapseChange: C,
    isCollapsed: u = !1
  } = i, [l, v] = g(u), y = () => {
    C && C(!l), v((o) => !o);
  };
  return /* @__PURE__ */ t(
    "div",
    {
      className: s(e.wrapper, f, e[N], {
        [e.inline]: !r
      }),
      "data-qd-test": i["data-qd-test"],
      children: [
        /* @__PURE__ */ t("div", { className: s(e.container, w.text), children: [
          /* @__PURE__ */ t("div", { className: e.titleContainer, children: [
            c && /* @__PURE__ */ a("header", { className: s(e.header, { [e.isCollapsed]: l }), children: c }),
            d && !p && /* @__PURE__ */ a(n, { "aria-label": "Close", className: "scalableIcon", onClick: d, icon: j })
          ] }),
          !l && /* @__PURE__ */ a("div", { children: r }),
          p && /* @__PURE__ */ a(
            n,
            {
              "aria-label": l ? "Expand" : "Collapse",
              className: s({ [e.isCollapsed]: l }),
              onClick: y,
              icon: q,
              iconClassName: s(e.chevronIcon, "scalableIcon")
            }
          )
        ] }),
        m && /* @__PURE__ */ a("div", { className: e.actions, children: m.map((o) => {
          if ("custom" in o)
            return /* @__PURE__ */ a(x, { children: o.custom }, o.key);
          const { children: b, key: h, ...I } = o;
          return /* @__PURE__ */ k(n, { ...I, key: h, "data-qd-test": h }, b);
        }) })
      ]
    }
  );
};
export {
  G as Notification
};
