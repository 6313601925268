import { jsxs as a, jsx as r } from "react/jsx-runtime";
import f from "@jetbrains/logos/qodana/qodana.js";
import s from "classnames";
import { useContext as p } from "react";
import "uuid";
import { isDark as g, getCurrentThemeColor as C } from "../../utils/theme.js";
import { useEnlargementCoefficient as b } from "../../hooks/useEnlargementCoefficient.js";
import { ThemeContext as N } from "../Theme/ThemeContext.js";
import "../Typography/Typography.js";
import o from "./Header.module.css.js";
import { HeaderSubmenu as I } from "./HeaderSubmenu.js";
import { ThemeToggle as T } from "./ThemeToggle/ThemeToggle.js";
import x from "../Typography/Typography.module.css.js";
function A(l) {
  const { className: i, userControls: c, menuItems: t = [] } = l, { theme: d, systemTheme: h, onThemeChange: m } = p(N), u = g(C(d, h)), n = 48 * b();
  return /* @__PURE__ */ a("header", { className: s(o.header, i), children: [
    /* @__PURE__ */ r(
      f,
      {
        className: o.logo,
        height: n,
        width: n,
        "aria-label": "Qodana product logo"
      }
    ),
    t.length > 0 && /* @__PURE__ */ r("div", { className: o.menuItems, children: t.map(
      (e) => e.type !== "submenu" ? /* @__PURE__ */ r(
        "button",
        {
          className: s(x.text, o.menuItem, o.rootMenuItem),
          onClick: e.click,
          disabled: e.enabled === !1,
          children: e.label
        },
        e.id || e.label
      ) : /* @__PURE__ */ r(
        I,
        {
          submenuClassName: o.rootMenuItem,
          className: o.menuItem,
          item: e
        },
        e.id || e.label
      )
    ) }),
    /* @__PURE__ */ a("div", { className: o.controls, children: [
      c,
      !!m && /* @__PURE__ */ r(T, { defaultChecked: u, onChange: m })
    ] })
  ] });
}
export {
  A as Header
};
