import { jsx as c } from "react/jsx-runtime";
import h from "@jetbrains/ring-ui-built/components/input/input.js";
import l from "@jetbrains/icons/search.js";
import t from "classnames";
const d = (e) => {
  const { onChange: a, className: r, ...n } = e;
  return /* @__PURE__ */ c(
    h,
    {
      icon: l,
      "aria-label": "search",
      placeholder: "Search",
      onChange: (o) => {
        a(o.target.value);
      },
      onClear: () => {
        a("");
      },
      className: t(r, "scalableIcon"),
      ...n
    }
  );
};
export {
  d as SearchInput
};
