import { jsxs as m, jsx as a } from "react/jsx-runtime";
import n from "@jetbrains/icons/close.js";
import { Button as d } from "@jetbrains/ring-ui-built/components/button/button.js";
import { H2 as f } from "@jetbrains/ring-ui-built/components/heading/heading.js";
import l from "classnames";
import e from "./Header.module.css.js";
const C = (s) => {
  const {
    className: c,
    titleTextClassName: r,
    closeButtonDisabled: i = !1,
    onClick: o,
    "data-qd-test": t
  } = s;
  return /* @__PURE__ */ m("header", { className: l(e.title, c), children: [
    /* @__PURE__ */ a(
      f,
      {
        className: l(e.titleText, r),
        "data-qd-test": t && `${t}__title`,
        children: s.children
      }
    ),
    !!o && /* @__PURE__ */ a(
      d,
      {
        "data-qd-test": t && `${t}__close-button`,
        iconClassName: e.closeButtonIcon,
        className: e.closeButton,
        onClick: o,
        icon: n,
        disabled: i
      }
    )
  ] });
};
export {
  C as Header
};
