const t = "_title_mzxvi_1", e = "_titleText_mzxvi_11", l = "_closeButton_mzxvi_15", o = {
  title: t,
  titleText: e,
  closeButton: l
};
export {
  l as closeButton,
  o as default,
  t as title,
  e as titleText
};
