import { jsxs as d, jsx as t } from "react/jsx-runtime";
import E from "@jetbrains/icons/eye-crossed.js";
import N from "@jetbrains/icons/eye.js";
import { Button as g } from "@jetbrains/ring-ui-built/components/button/button.js";
import w from "@jetbrains/ring-ui-built/components/code/code.js";
import x from "@jetbrains/ring-ui-built/components/tooltip/tooltip.js";
import m from "classnames";
import { useState as B } from "react";
import { CopyButton as H } from "../CopyButton/CopyButton.js";
import o from "./Code.module.css.js";
import { TestAnchor as T } from "./test.js";
const G = (c) => {
  const {
    code: n,
    language: l,
    codeWithHiddenElements: i,
    hiddenPartName: s = "code",
    copyButtonTestAttribute: a,
    inline: e = !1,
    horizontalScroll: p = !1,
    onCopy: f,
    ...h
  } = c, [r, C] = B(!0), u = () => C((S) => !S), y = i !== void 0 && r ? i : n;
  return /* @__PURE__ */ d("div", { ...h, className: m(o.container, { [o.inlineContainer]: e }), children: [
    !e && /* @__PURE__ */ d("div", { className: o.buttonGroups, children: [
      i !== void 0 && /* @__PURE__ */ t(
        x,
        {
          title: r ? `Show ${s}` : `Hide ${s}`,
          children: /* @__PURE__ */ t(
            g,
            {
              "data-qd-test": T.eyeButton,
              icon: r ? N : E,
              iconClassName: "scalableIcon",
              onClick: u
            }
          )
        }
      ),
      /* @__PURE__ */ t(H, { "data-qd-test": a, textToCopy: n, onCopy: f })
    ] }),
    /* @__PURE__ */ t(
      w,
      {
        className: m(o.code, {
          [o.inlineCode]: e,
          [o.noScroll]: !p
        }),
        inline: e,
        language: l,
        code: y
      }
    )
  ] });
};
export {
  G as Code
};
