const c = "_wrapper_1awvc_1", n = "_inline_1awvc_8", e = "_container_1awvc_12", a = "_critical_1awvc_16", o = "_moderate_1awvc_21", t = "_info_1awvc_26", _ = "_success_1awvc_31", s = "_header_1awvc_36", i = "_isCollapsed_1awvc_45", r = "_titleContainer_1awvc_49", l = "_chevronIcon_1awvc_54", v = "_actions_1awvc_63", w = {
  wrapper: c,
  inline: n,
  container: e,
  critical: a,
  moderate: o,
  info: t,
  success: _,
  header: s,
  isCollapsed: i,
  titleContainer: r,
  chevronIcon: l,
  actions: v
};
export {
  v as actions,
  l as chevronIcon,
  e as container,
  a as critical,
  w as default,
  s as header,
  t as info,
  n as inline,
  i as isCollapsed,
  o as moderate,
  _ as success,
  r as titleContainer,
  c as wrapper
};
