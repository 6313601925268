import { jsxs as x, Fragment as h, jsx as t } from "react/jsx-runtime";
import v from "classnames";
import { forwardRef as N } from "react";
import e from "./Input.module.css.js";
const b = N((o, n) => {
  const {
    className: a,
    controlName: s,
    disabled: i,
    error: r,
    id: m,
    onChange: d,
    placeholder: l,
    type: p = "text",
    invalid: c,
    value: f,
    ...u
  } = o;
  return /* @__PURE__ */ x(h, { children: [
    /* @__PURE__ */ t(
      "input",
      {
        className: v(e.qdInput, a, { [e.invalid]: c }),
        disabled: i,
        id: m,
        name: s,
        placeholder: l,
        type: p || "text",
        value: f,
        onChange: d,
        ref: n,
        ...u
      }
    ),
    r !== void 0 && /* @__PURE__ */ t("div", { className: e.error, children: r })
  ] });
});
export {
  b as Input
};
