import { jsx as o, jsxs as l } from "react/jsx-runtime";
import { useState as d, useEffect as f } from "react";
import g from "../../../media/images/crescent.svg.js";
import p from "../../../media/images/sun.svg.js";
import { handlePositiveKeyDown as w } from "../../../utils/keyboard.js";
import { Theme as n } from "../../Theme/ThemeContext.js";
import e from "./ThemeToggle.module.css.js";
const S = (m) => {
  const { defaultChecked: a = !1, onChange: t } = m, [s, c] = d(a), r = () => {
    c(!s), t && t(s ? n.light : n.dark);
  }, h = (i) => {
    w(i, r), i.key === "a" && (alert("Theme will be synced with system"), t && t(n.auto));
  };
  return f(() => {
    c(a);
  }, [a]), /* @__PURE__ */ o(
    "span",
    {
      className: e.checkbox,
      onClick: r,
      onKeyDown: h,
      role: "switch",
      "aria-checked": s,
      "aria-label": "toggle dark theme",
      tabIndex: 0,
      "data-qd-test": "theme-toggle",
      children: /* @__PURE__ */ l("span", { className: e.helper, tabIndex: -1, children: [
        /* @__PURE__ */ o("span", { className: `${e.switch} ${s ? e.on : ""}` }),
        /* @__PURE__ */ l("div", { className: e.iconContainer, children: [
          /* @__PURE__ */ o(p, { className: e.sunIcon }),
          /* @__PURE__ */ o(g, { className: e.crescentIcon })
        ] })
      ] })
    }
  );
};
export {
  S as ThemeToggle
};
