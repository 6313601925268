import { jsx as r, jsxs as q } from "react/jsx-runtime";
import M from "@jetbrains/ring-ui-built/components/popup/popup.js";
import d from "classnames";
import { useState as v, useRef as E, useContext as F, useEffect as S, useCallback as m } from "react";
import U from "react-focus-lock";
import { MODAL_ANIMATION_DURATION as j } from "../../config/globalConstants.js";
import { useIsMountedRef as W } from "../../hooks/useIsMountedRef.js";
import { useNoscroll as T } from "../../hooks/useNoscroll.js";
import "@jetbrains/ring-ui-built/components/global/theme.js";
import "../Theme/ThemeContext.js";
import "@jetbrains/ring-ui-built/components/alert-service/alert-service.js";
import { FocusRestoreContext as $ } from "../../services/focusRestore/FocusRestoreContext.js";
import "../../services/telemetry/telemetry.js";
import { Header as G } from "./Header/Header.js";
import e from "./ModalWrapper.module.css.js";
const z = 670, J = 500, K = (o) => {
  o.stopPropagation(), o.preventDefault();
}, de = (o) => {
  const {
    className: g = "",
    keyboard: D = !0,
    maskClosable: y = !0,
    showCloseButton: A = !0,
    onClose: a,
    closeButtonDisabled: I = !1,
    opened: i,
    title: f = null,
    titleClassName: _,
    titleTextClassName: b,
    popupContentClassName: w,
    focusLockClassName: x,
    width: p = z,
    height: L = J,
    maxHeight: B,
    wrapInBlockTag: H = !0
  } = o, [c, C] = v(!0), s = E(), h = W(), O = A && !!a, t = F($);
  S(
    () => () => {
      s.current && (clearTimeout(s.current), s.current = void 0);
    },
    [i]
  );
  const l = m(() => {
    !a || s.current || (C(!1), s.current = setTimeout(() => {
      a(), h.current && C(!0);
    }, j));
  }, [h, a]), P = m(
    (n) => {
      n.target.getAttribute("id") === `qd_modal_${e.container}` && l();
    },
    [l]
  ), R = m(() => {
    var n, u, k;
    typeof t == "string" ? (u = (n = document.getElementById(t)) == null ? void 0 : n.focus) == null || u.call(n) : (k = t == null ? void 0 : t.focus) == null || k.call(t);
  }, [t]);
  if (T(document.body, i), T(document.body.parentElement || void 0, i), !i)
    return null;
  const N = H ? "div" : "span";
  return /* @__PURE__ */ r(
    N,
    {
      id: `qd_modal_${e.container}`,
      className: d(e.container, {
        [e.opened]: c,
        [e.closed]: !c
      }),
      onClick: K,
      "aria-hidden": !0,
      children: /* @__PURE__ */ r(N, { style: { width: p }, children: /* @__PURE__ */ r(
        M,
        {
          className: d(
            e.popup,
            {
              [e.opened]: c,
              [e.closed]: !c
            },
            g
          ),
          style: { width: p, height: L, maxHeight: B },
          "data-test": o["data-qd-e2e-selector"],
          onEscPress: D ? l : void 0,
          onOutsideClick: y ? P : void 0,
          autoCorrectTopOverflow: !1,
          autoPositioning: !1,
          children: /* @__PURE__ */ q(
            U,
            {
              className: d(e.focusLock, x),
              returnFocus: !0,
              onDeactivation: R,
              children: [
                !!f && /* @__PURE__ */ r(
                  G,
                  {
                    "data-qd-test": o["data-qd-e2e-selector"],
                    className: _,
                    titleTextClassName: b,
                    onClick: O ? l : void 0,
                    closeButtonDisabled: I,
                    children: f
                  }
                ),
                /* @__PURE__ */ r("div", { className: d(e.popupContent, w), children: o.children })
              ]
            }
          )
        }
      ) })
    }
  );
};
export {
  de as ModalWrapper
};
